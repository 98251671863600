import { HStack, VStack } from '@domir/react-stacks';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';

import { Tag } from '../../../shared/src/Tag';
import { thingosColors } from '../../../shared/src/Theme/theme';
import { Button } from '../components/Button';
import { Card } from '../components/Card';
import Container, { ColorContainer } from '../components/Container';
import { Page } from '../components/Page';
import { HeaderH1, HeaderH2 } from '../components/Typography';
import { IndexLayout } from '../layouts';

const Stack = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	gap: 8,
	'@media only screen and (max-width: 600px)': {
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
});

const JobsPage = () => {
	const { t } = useTranslation('jobs');

	return (
		<IndexLayout>
			<Page>
				<ColorContainer backgroundColor="#fbfbfb">
					<Container>
						<HeaderH1>{t('jobs.title')}</HeaderH1>
						<JobSection
							title="Project Manager in Software Development – Full-Time"
							area="📈 Project Management"
							color="#22b1b9"
							link="/jobDetails/projectManagerFulltime"
						/>
						<JobSection
							title="Web Developer – Student employee"
							area="💻 Web Development"
							color="#fc984c"
							link="/jobDetails/webDeveloperStudent"
						/>
						<JobSection
							title="Web Developer – Full-Time"
							area="💻 Web Development"
							color="#fc984c"
							link="/jobDetails/webDeveloperFulltime"
						/>
						<JobSection
							title="Embedded Software Engineer – Student employee"
							area="💻 Embedded Development"
							color="#bf26b6"
							link="/jobDetails/embeddedSoftwareEngineerStudent"
						/>
						<JobSection
							title="Embedded Software Engineer – Full-Time"
							area="💻 Embedded Development"
							color="#bf26b6"
							link="/jobDetails/embeddedSoftwareEngineerFulltime"
						/>
						<JobSection
							title="Back-End Developer – Student employee"
							area="💻 Back-End Development"
							color="#bf26b6"
							link="/jobDetails/backendDeveloperFulltime"
						/>
						<JobSection
							title="Back-End Developer – Full-Time"
							area="💻 Back-End Development"
							color="#bf26b6"
							link="/jobDetails/backendDeveloperFulltime"
						/>
					</Container>
				</ColorContainer>
			</Page>
		</IndexLayout>
	);
};

type JobSectionProps = {
	title: string;
	link: string;
	area: string;
	color: string;
};
const JobSection: React.FC<JobSectionProps> = ({ title, area, color, link }) => {
	return (
		<Card style={{ maxWidth: '100%', padding: '16px', color: `##555555` }}>
			<HStack
				alignItems="center"
				style={{ marginTop: 8, marginBottom: 8, padding: '0px 16px', backgroundColor: 'white' }}
			>
				<VStack flex>
					<HeaderH2>{title}</HeaderH2>
					<Stack>
						<Tag color={thingosColors.highlight.main}>📍 Stuttgart, DE</Tag>
						<Tag color={color}>{area}</Tag>
					</Stack>
				</VStack>
				<Button href={link}>Read more</Button>
			</HStack>
		</Card>
	);
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

export default JobsPage;
